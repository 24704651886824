import React from 'react';
import axios from 'axios';
import { Link } from 'gatsby';
import YoutubePlayer from '../components/youtubePlayer';

export default class TrainingPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
    }
  }

  getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(this.props.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  componentDidMount() {
    try {
      const WOW = require('wowjs');
      const wow = new WOW.WOW();
      wow.init();
    } catch (e) {
      console.error(e);
    }

    axios
      .get(`${process.env.API_URL}/umbraco/api/news/get?take=20`)
      .then(result => {
        this.setState({
          data: result.data,
        })
      })
      .catch(error => { })
  }

  render() {
    const { data } = this.state;

    return (
      <div>
        <section className="section-top">
          <div className="container">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="section-top-title">
                <h2>Notícias</h2>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12" />
          </div>
        </section>
        <section className="our_activites">
          <div className="container">
            <div className="row text-center">
              {
                data.length > 0 ?
                  data.map((news, idx) => {
                    const photoUrl = `${process.env.API_URL}${news.Photo}`;
                    const linkUrl = `/news-detail${news.Url.substring(0, news.Url.length - 1)}`;

                    let columnItem = null;

                    if (news.Photo) {
                      columnItem = (
                        <div className="grid-item">
                          <img alt="Logo" src={photoUrl} />
                        </div>
                      );
                    } else if(news.Video) {
                      columnItem = (
                        <div className="grid-item">
                          <YoutubePlayer url={news.Video}  />
                        </div>
                      );
                    }

                    const containerClass = columnItem ? "grid-container-2x-lg" : "";

                    return (
                      <div key={idx} className="col-sm-12">
                        <Link to={linkUrl}>
                          <div className="single_activites card xl">
                            <div className={containerClass}>
                              {columnItem}
                              <div className="grid-item training-card-text">
                                <h4 className="text inline">{news.Name.replace(/&nbsp;/g, '')}</h4>
                                {/* <div className="inline pull-right hidden-xs">
                                  <h4 className="text inline">Data:</h4>
                                  <span className="text">{news.PublishedOn}</span>
                                </div> */}
                                <h4 className="text"></h4>
                                <div className="training-card-text-container list-fade training-list" dangerouslySetInnerHTML={{ __html: news.Content }} />
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  }) :
                  <span>Sem Notícias</span>
              }
            </div>
          </div>
        </section>
      </div>
    )
  }
}